/* eslint-disable react/no-array-index-key */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import {
  Hero5,
  Service,
  SolutionItems,
  // QuickScan,
  HeadingLayout,
} from 'components/elements'

const PageTemplate = ({
  data: {
    page: { wordpress_id: wordpressId, path, acf, yoast_meta: yoast },
  },
}) => {
  return (
    <Layout activeHeader="Oplossingen">
      <SEO yoast={yoast} path={path} />

      <Hero5
        fields={acf.banner}
      />

      {acf.blocks.map((b, index) => (
        <div key={index} className="py-lg-5 mt-5">
          <div className="pt-5">
            <Service
              fields={b}
              direction={index % 2 === 0 ? "right" : "left"}
              backgroundTitle={b.background_title}
            />
          </div>
        </div>
      ))}

      <div className="py-lg-5 mt-5">
        <div className="pt-5">
          <HeadingLayout heading={acf.references.background_description}>
            <SolutionItems id={wordpressId} type={2} />
          </HeadingLayout>
        </div>
      </div>

      <div className="py-5" />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      wordpress_id

      acf {
        banner {
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        blocks {
          background_title
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }

        references {
          background_description
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
